import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { resourceCheck, Permission, ResourceType } from '../helper/resourceVerifyHelper';
import { RootState } from '../app/store';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import {
    getUserProfile,
    getUserGroups,
    getGroupsMapping,
    updateUserGroup,
    getUserListByGroups,
} from '../services/userProfile';
import { Audience } from './chatSlice';
import { UserWithGroup } from '../interface/UserInfo';

export interface UserProfileGroup {
    id: number;
    name: string;
    adGroup: string;
    audiences: Audience[];
    permissionList?: Permission[];
}
export interface UserProfile {
    name: string;
    email: string;
    firstName: string;
    lastName: string;
    galacxyId: string;
    userId: string;
    groups: UserProfileGroup[];
    adGroupId: string;
    selectedGroupId?: number;
    currentGroupId?: number;
    currentGroupName: string;
    currentAdGroup?: string;
    chatAudiences?: Audience[];
    currentPermissionList?: Permission[];
}

export interface Groups {
    id: number;
    adGroup: string;
    displayName: string;
    avatarBackgroundColor: string;
    avatarTextColor: string;
    remarks?: string;
    categories?: string[];
}

export interface UserGroups {
    [key: string]: {
        groupIds: number[];
        name: string;
    };
}

export interface UserListRequestBody {
    groupIds: number[];
    userName?: string;
    galacxyId?: string;
    active?: boolean;
}

export const getUserProfileThunk = createAsyncThunk<UserProfile, null, { rejectValue: ApiErrorObj }>(
    'userProfile/getUserProfile',
    async (_, { rejectWithValue }) => {
        const [err, data] = await getUserProfile();

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data as UserProfile;
    }
);

export const getUserGroupsThunk = createAsyncThunk<
    UserGroups,
    { galacxyIds: string[] },
    { state: RootState; rejectValue: ApiErrorObj }
>('userProfile/getUserGroups', async ({ galacxyIds }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/getUserGroups')) {
        return rejectWithValue(ForbiddenObj);
    }

    const unSavedGID = galacxyIds.filter((item) => !getState().userProfile.userGroups[item]);

    if (unSavedGID.length > 0) {
        const [err, data] = await getUserGroups(galacxyIds);

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data;
    }

    return {};
});

export const getGroupsMappingThunk = createAsyncThunk<Groups[], null, { rejectValue: ApiErrorObj }>(
    'userProfile/getGroupsMapping',
    async (_, { rejectWithValue }) => {
        const [err, data] = await getGroupsMapping();

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data as Groups[];
    }
);

export const updateUserGroupThunk = createAsyncThunk<
    UserProfile[],
    { targetGroupId: number },
    { rejectValue: ApiErrorObj }
>('userProfile/updateUserGroup', async ({ targetGroupId }, { rejectWithValue }) => {
    const [err] = await updateUserGroup({ targetGroupId });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return;
});

export const getUserListByGroupsThunk = createAsyncThunk<
    UserWithGroup[],
    UserListRequestBody,
    { state: RootState; rejectValue: ApiErrorObj }
>('userProfile/users', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/users')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getUserListByGroups(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export interface UserProfileSlice {
    userProfile: UserProfile;
    userGroups: UserGroups;
    isProfileLoading: boolean;
    groupsMapping: Groups[];
}

const initialState: UserProfileSlice = {
    userProfile: null,
    userGroups: {},
    isProfileLoading: false,
    groupsMapping: null,
};

export const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserProfileThunk.pending, (state) => {
                state.isProfileLoading = true;
                state.userProfile = initialState.userProfile;
            })
            .addCase(getUserProfileThunk.fulfilled, (state, { payload }) => {
                state.isProfileLoading = false;
                const { groups = [] }: { groups: UserProfileGroup[] } = payload;
                let { selectedGroupId } = payload;
                if (!selectedGroupId) {
                    [selectedGroupId] = groups.map((group) => group.id);
                }

                const chatAudiences = groups.find((group) => group.id === selectedGroupId).audiences;

                const currentGroupInfo = groups.find((group) => group.id === selectedGroupId);
                const currentGroupName = currentGroupInfo?.name;
                const currentAdGroup = currentGroupInfo?.adGroup;

                state.userProfile = {
                    ...payload,
                    chatAudiences,
                    currentGroupId: selectedGroupId,
                    currentGroupName,
                    currentAdGroup,
                };
            })
            .addCase(getUserProfileThunk.rejected, (state) => {
                state.isProfileLoading = false;
            })
            .addCase(getUserGroupsThunk.fulfilled, (state, { payload }) => {
                state.isProfileLoading = false;

                if (Object.keys(payload).length > 0) {
                    state.userGroups = Object.assign(state.userGroups || {}, payload);
                }
            })
            .addCase(getUserGroupsThunk.rejected, (state) => {
                state.isProfileLoading = false;
            })
            .addCase(getGroupsMappingThunk.pending, (state) => {
                state.isProfileLoading = true;
            })
            .addCase(getGroupsMappingThunk.fulfilled, (state, { payload }) => {
                state.isProfileLoading = false;
                state.groupsMapping = payload;
            })
            .addCase(getGroupsMappingThunk.rejected, (state) => {
                state.isProfileLoading = false;
            })
            .addCase(updateUserGroupThunk.pending, (state) => {
                state.isProfileLoading = true;
            })
            .addCase(updateUserGroupThunk.fulfilled, (state) => {
                state.isProfileLoading = false;
            })
            .addCase(updateUserGroupThunk.rejected, (state) => {
                state.isProfileLoading = false;
            });
    },
});

export const selectUserProfile = (state: RootState) => state.userProfile;

export default userProfileSlice.reducer;
