import { createSlice, createAsyncThunk, PayloadAction, createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { last, isEmpty } from 'lodash/fp';
import { RootState, store } from '../app/store';
import { downloadBlob } from './fileSlice';
import { FlightObj } from './flightSlice';
import { ChatComposerSubmitObj } from '../components/chat/ChatComposer';
import { ChatServerErrorObj, ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import {
    getAndReadChatMessages,
    postChatMessage,
    getChatAttachedImage,
    getChatMetaDataByAudience,
    getFlightByChatUfis,
    markAllChatRead,
} from '../services/chat';
import globalConfig from '../config/globalConfig';
import { resourceCheck, ResourceType } from '../helper/resourceVerifyHelper';

const chatAppId = globalConfig.chatAppId;
const chatImageFileName = 'image.jpg';

export interface ChatMetaData {
    channelId: string;
    channelName: string;
    meta: {
        audience: string;
        count: number;
        totalCount: number;
        latestMessage: {
            createdAt: string;
            createdBy: string;
        };
        lastUpdated: string;
        tags: {
            [key: string]: {
                totalCount: number;
                unread: number;
            };
        };
    }[];
}
export interface EventMessage {
    audiences: string[];
    channelId: string;
    createdAt: string;
    createdBy: string;
    event: {
        assignedTo: string;
        audiences: string;
        creatorGroup: string;
        eventId: string;
        iconUrl: string;
        taskDetail: string;
        taskFrom: string;
        title: string;
        triggeredBy: string;
        isNew: boolean;
        messageType: string;
        eventTaskId: number;
    };
    id: string;
    redisId: string;
    tags: string[];
    updatedAt: string;
    __v: number;
    _id: string;
}

export interface ChatMessage {
    id: string;
    audiences: string[];
    channelId: string; // GLOBAL~CX47320211020TPE
    content: string;
    resource?: ChatResources;
    createdBy: string; // galaxcy id
    createdAt: string;
    updatedAt: string;
    replyTo: ChatMessage;
    tags?: string[];
}

export interface Audience {
    id: number;
    channel: string;
    name: string;
    remarks: string;
    groupId: number[];
    audiences: string;
    lastUpdated?: string;
}

export interface FlightChat {
    ufi: string;
    channelName: string;
    flight: FlightObj;
    audiences: Audience[];
}
export interface ChatResources {
    key: string;
    thumbnail: string;
    mimetype: string;
    originalName: string;
}

export interface FlightByChatChannel {
    metaUfi: string;
    flight: FlightObj;
}
interface ChatSlice {
    isChatLoading: boolean;
    isChatMetaLoading: boolean;
    flightList: FlightChat[];
    chatMessageList: {
        [key: string]: {
            [key: string]: EntityState<ChatMessage, string>;
        };
    };
    chatReadStatus: {
        [key: string]: {
            [key: string]: {
                isRead?: boolean; // if true === read all message
                isTagged?: boolean;
            };
        };
    };
    chatErrorObj: ApiErrorObj;
    selectedChat: FlightChat;
    newlyAddedFlight: { ufi: string; channel?: string };
    eventListToChatUfi: string;
}

const chatMessageAdapter = createEntityAdapter<ChatMessage>({
    // behave same as array.sort()
    // sort according to updatedAt time of the chat message.
    sortComparer: (a, b) => new Date(a.updatedAt).valueOf() - new Date(b.updatedAt).valueOf(),
});

export const chatMessageAdapterSelectors = chatMessageAdapter.getSelectors();
export const getChatMessageListByAudience = (channel: string, audience: string) => {
    if (!channel || !audience) return [];
    return chatMessageAdapterSelectors.selectAll(store.getState().chat.chatMessageList?.[channel]?.[audience]);
};

export interface flightChannelParam {
    ufi: [string];
    active: boolean;
}

const initialState: ChatSlice = {
    isChatLoading: false,
    isChatMetaLoading: false,
    flightList: [],
    chatMessageList: {},
    chatReadStatus: {},
    selectedChat: null,
    chatErrorObj: null,
    newlyAddedFlight: null,
    eventListToChatUfi: null,
};

export const getAndReadChatMessagesThunk = createAsyncThunk<
    ChatMessage[],
    null,
    { state: RootState; rejectValue: ChatServerErrorObj }
>('chat/getAndReadChatMessages', async (_, { getState, rejectWithValue, dispatch }) => {
    const { userProfile } = getState().userProfile;
    const { selectedChat } = getState().chat;
    const { galacxyId } = userProfile;
    const { channelName, audiences } = selectedChat || {};
    const audience = audiences?.[0]?.audiences;

    if (!audience || !channelName) {
        return [];
    }

    const lastChatMessage =
        chatMessageAdapter
            .getSelectors()
            .selectAll(getState().chat.chatMessageList[channelName][audience])
            ?.slice(-1)[0] || ({} as ChatMessage);

    const lastUpdated = lastChatMessage.updatedAt;

    const [err, data] = await getAndReadChatMessages({
        userId: galacxyId,
        lastUpdated,
        appId: chatAppId,
        globalChannelName: channelName,
        audiences: [audience],
    });

    if (err) {
        return rejectWithValue(err as ChatServerErrorObj);
    }

    // update the frontend read status when success call
    dispatch(updateChatReadStatus({ channelName, audience, isRead: true, isTagged: false }));

    return data as ChatMessage[];
});

export const postChatMessageThunk = createAsyncThunk<
    ChatMessage[],
    ChatComposerSubmitObj,
    { state: RootState; rejectValue: ChatServerErrorObj }
>(
    'chat/postChatMessage',
    async ({ replyToMessageId, message, file, tags, activeGroupId }, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { selectedChat } = getState().chat;
        const { channelName, audiences } = selectedChat || {};
        const audience = audiences?.[0]?.audiences || '';
        const { galacxyId } = userProfile;

        const formData = new FormData();
        formData.append('createdBy', galacxyId);
        formData.append('appId', chatAppId);
        formData.append('globalChannelName', channelName);
        formData.append('activeGroupId', activeGroupId);
        formData.append('audiences[]', audience);
        formData.append('content', message || 'Attachment');

        if (replyToMessageId) {
            formData.append('replyTo', replyToMessageId);
        }

        // attachment is optional
        if (file) {
            formData.append('photo', file);
        }

        if (tags?.length > 0) {
            tags.map((tag) => formData.append('tags[]', tag));
        }

        const [err, data] = await postChatMessage(formData);

        if (err) {
            return rejectWithValue(err as ChatServerErrorObj);
        }

        return data as ChatMessage[];
    }
);

export const getChatAttachedImageThunk = createAsyncThunk<
    { blob: Blob; messageId },
    { messageId: string },
    { state: RootState; rejectValue: ChatServerErrorObj }
>('chat/getChatAttachedImage', async ({ messageId }, { getState, rejectWithValue }) => {
    const [err, data] = await getChatAttachedImage({ messageId });

    if (err) {
        return rejectWithValue(err as ChatServerErrorObj);
    }

    return { blob: new Blob([data], { type: 'image/jpeg' }), messageId };
});

const getChatMetaDataByAudienceCall = async ({ lastUpdated }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { chatAudiences = [], galacxyId, currentAdGroup } = userProfile;
    const targetSubscribeAudiences = chatAudiences.map((item) => item.audiences);

    if (targetSubscribeAudiences.length === 0) {
        return { data: [], galaxyId: galacxyId };
    }

    const fetchAll = async ({
        lastUpdatedStr,
        offset,
        limit,
    }: {
        lastUpdatedStr: string;
        offset: number;
        limit: number;
    }): Promise<[object, any[]]> => {
        let allData = [];
        let error = {},
            data = [];
        [error, data] = await getChatMetaDataByAudience({
            lastUpdated: lastUpdatedStr,
            audiences: targetSubscribeAudiences,
            tags: [currentAdGroup],
            offset,
            limit,
        });

        if (!error) {
            allData = allData.concat(data);

            // trigger next call if no error and data length equal to limit
            if (data?.length === limit) {
                [error, data] = await fetchAll({ lastUpdatedStr, offset: offset + limit, limit });

                if (!error && data) {
                    allData = allData.concat(data);
                }
            }
        }

        return [error, allData];
    };

    const [err, data] = await fetchAll({
        lastUpdatedStr: lastUpdated.toISO(),
        offset: 0,
        limit: 50,
    });

    if (err) {
        return rejectWithValue(err as ChatServerErrorObj);
    }

    return { data, galaxyId: galacxyId };
};

export const getChatMetaDataByAudienceThunk = createAsyncThunk<
    { data: ChatMetaData[]; galaxyId: string },
    { lastUpdated: DateTime },
    { state: RootState; rejectValue: ChatServerErrorObj }
>('chat/getChatMetaDataByAudience', getChatMetaDataByAudienceCall);

export const getChatMetaDataByAudienceInBackgroundThunk = createAsyncThunk<
    { data: ChatMetaData[]; galaxyId: string },
    { lastUpdated: DateTime },
    { state: RootState; rejectValue: ChatServerErrorObj }
>('chat/getChatMetaDataByAudienceInBackground', getChatMetaDataByAudienceCall);

const getFlightByChatUfisCall = async ({ chatUfis }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/getFlightByChatUfis')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getFlightByChatUfis({ chatUfis });
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as FlightByChatChannel[];
};

export const getFlightByChatUfisThunk = createAsyncThunk<
    FlightByChatChannel[],
    { chatUfis: string[] },
    { state: RootState; rejectValue: ApiErrorObj }
>('chat/getFlightByChatUfis', getFlightByChatUfisCall);

export const getFlightByChatUfisInBackgroundThunk = createAsyncThunk<
    FlightByChatChannel[],
    { chatUfis: string[] },
    { state: RootState; rejectValue: ApiErrorObj }
>('chat/getFlightByChatUfisInBackground', getFlightByChatUfisCall);

export const markAllChatReadThunk = createAsyncThunk<
    { data: ChatMetaData[]; galaxyId: string },
    null,
    { state: RootState; rejectValue: ApiErrorObj }
>('chat/markAllChatRead', async (param, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { flightList = [] } = getState().chat;
    const { chatAudiences = [] } = userProfile || {};

    const subscribedAudiences = chatAudiences.map((item) => item.audiences);

    const markReadTargets = flightList.reduce((targetsObj, flight) => {
        targetsObj[flight.channelName] = subscribedAudiences;
        return targetsObj;
    }, {});

    const [err, data] = await markAllChatRead(markReadTargets);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        updateChatReadStatus: (
            state,
            action: PayloadAction<{ channelName: string; audience: string; isRead: boolean; isTagged: boolean }>
        ) => {
            const { channelName, audience, isRead, isTagged } = action.payload;
            state.chatReadStatus[channelName] = state.chatReadStatus[channelName] ?? {};
            state.chatReadStatus[channelName][audience] = state.chatReadStatus[channelName][audience] ?? {};

            const hasChannelTagged = state.chatReadStatus[channelName][audience].isTagged;
            state.chatReadStatus[channelName][audience].isRead = isRead;
            state.chatReadStatus[channelName][audience].isTagged = isRead ? false : hasChannelTagged || isTagged;
        },
        setNewlyAddedFlight: (state, action: PayloadAction<{ ufi: string; channel?: string }>) => {
            state.newlyAddedFlight = action.payload;
        },
        setEventListToChatUfi: (state, action: PayloadAction<string>) => {
            state.eventListToChatUfi = action.payload;
        },
        selectChatroom: (state, action: PayloadAction<FlightChat>) => {
            if (action.payload === null) {
                state.selectedChat = null;
                return;
            }

            const { ufi: selectedChatUfi, audiences: selectedAudiences } = state.selectedChat || {};
            const [selectedChatAudience] = selectedAudiences || [];

            const { ufi: targetChatUfi, audiences: targetAudiences, channelName } = action.payload || {};
            const [targetChatAudience] = targetAudiences || [];
            if (selectedChatUfi === targetChatUfi && selectedChatAudience?.id === targetChatAudience?.id) return;

            if (!state.chatMessageList[channelName]) {
                state.chatMessageList[channelName] = {};
            }

            if (!state.chatMessageList[channelName][targetChatAudience.audiences]) {
                state.chatMessageList[channelName][targetChatAudience.audiences] = chatMessageAdapter.getInitialState();
            }

            state.selectedChat = action.payload;
        },
        addFlightChannels: (state, action: PayloadAction<FlightChat[]>) => {
            const { payload: targetFlightChannels = [] } = action;
            const combinedList = targetFlightChannels.concat(state.flightList);
            const uniqueFlightListMap = new Map(combinedList.map((flight) => [flight.ufi, flight]));
            const newFlightList = [...uniqueFlightListMap.values()];

            state.flightList = newFlightList;
        },
        moveChannelToFlightChannelsTop: (
            state,
            action: PayloadAction<{ targetFlightChannels: FlightChat[]; channelName: string }>
        ) => {
            const { targetFlightChannels, channelName } = action.payload;

            if (state.flightList[0]?.channelName === channelName) return;

            state.flightList = targetFlightChannels.concat(
                state.flightList.filter((channel) => channel.channelName !== channelName)
            );
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isChatLoading = action.payload;
        },
        removeEmptyChat: (state, action: PayloadAction<FlightChat>) => {
            const targetFlight = action.payload;
            if (!targetFlight) return;

            // No message object
            if (!state.chatMessageList?.[targetFlight.channelName]) {
                state.flightList = state.flightList.filter((flight: FlightChat) => flight.ufi !== targetFlight.ufi);
                return;
            }

            // Has message object but all audience message empty
            const audiences = Object.keys(state.chatMessageList[targetFlight.channelName]) || [];
            const shouldRemove = audiences.every(
                (audience) =>
                    chatMessageAdapter
                        .getSelectors()
                        .selectAll(state.chatMessageList[targetFlight.channelName][audience]).length <= 0
            );

            if (!shouldRemove) return;

            state.flightList = state.flightList.filter((flight: FlightChat) => flight.ufi !== targetFlight.ufi);
        },
        updateAllChannelLastUpdatedTime: (state, action: PayloadAction<string>) => {
            state.flightList = state.flightList.map((channelInfo) => {
                const { audiences } = channelInfo;

                const updatedAudiences = audiences.map((audienceInfo) => {
                    return {
                        ...audienceInfo,
                        lastUpdated: action.payload,
                    };
                });

                return {
                    ...channelInfo,
                    audiences: updatedAudiences,
                };
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAndReadChatMessagesThunk.pending, (state) => {})
            .addCase(getAndReadChatMessagesThunk.fulfilled, (state, { payload }) => {
                const { channelName: selectedChannelName, audiences } = state.selectedChat || {};
                const selectedAudienceName = audiences?.[0]?.audiences;
                if (selectedAudienceName && !isEmpty(payload)) {
                    const { createdAt: messageLastUpdated } = last(payload);

                    chatMessageAdapter.addMany(
                        state.chatMessageList[selectedChannelName][selectedAudienceName],
                        payload
                    );

                    // update flight list last update time
                    state.flightList = state.flightList.map((channelInfo) => {
                        const { channelName, audiences } = channelInfo;
                        if (selectedChannelName !== channelName) return channelInfo;

                        const updatedAudiences = audiences.map((audienceInfo) => {
                            const { audiences: audienceName } = audienceInfo;
                            if (selectedAudienceName !== audienceName) return audienceInfo;
                            return {
                                ...audienceInfo,
                                lastUpdated: messageLastUpdated,
                            };
                        });

                        return {
                            ...channelInfo,
                            audiences: updatedAudiences,
                        };
                    });
                }
            })
            .addCase(getAndReadChatMessagesThunk.rejected, (state) => {})
            .addCase(postChatMessageThunk.pending, (state) => {
                state.isChatLoading = true;
            })
            .addCase(postChatMessageThunk.fulfilled, (state) => {
                state.isChatLoading = false;
            })
            .addCase(postChatMessageThunk.rejected, (state) => {
                state.isChatLoading = false;
            })
            .addCase(getChatAttachedImageThunk.pending, () => {})
            .addCase(getChatAttachedImageThunk.fulfilled, (_, { payload }) => {
                const { blob } = payload;
                downloadBlob({
                    blob,
                    fileName: chatImageFileName,
                });
            })
            .addCase(getChatAttachedImageThunk.rejected, () => {})
            .addCase(getChatMetaDataByAudienceThunk.pending, (state) => {
                state.isChatLoading = true;
            })
            .addCase(getChatMetaDataByAudienceThunk.fulfilled, (state, { payload }) => {
                handleMetaDataUpdate(state, payload);
                state.isChatLoading = false;
            })
            .addCase(getChatMetaDataByAudienceThunk.rejected, (state) => {
                state.isChatLoading = false;
            })
            .addCase(getChatMetaDataByAudienceInBackgroundThunk.pending, (state, { payload }) => {
                state.isChatMetaLoading = true;
            })
            .addCase(getChatMetaDataByAudienceInBackgroundThunk.rejected, (state, { payload }) => {
                state.isChatMetaLoading = false;
            })
            .addCase(getChatMetaDataByAudienceInBackgroundThunk.fulfilled, (state, { payload }) => {
                state.isChatMetaLoading = false;
                handleMetaDataUpdate(state, payload);
            })
            .addCase(getFlightByChatUfisThunk.pending, (state) => {
                state.isChatLoading = true;
            })
            .addCase(getFlightByChatUfisThunk.fulfilled, (state) => {
                state.isChatLoading = false;
            })
            .addCase(getFlightByChatUfisThunk.rejected, (state) => {
                state.isChatLoading = false;
            });
    },
});

const handleMetaDataUpdate = (state, payload) => {
    payload.data?.forEach((flight) => {
        const { channelName, meta } = flight;
        state.chatReadStatus[channelName] = state.chatReadStatus[channelName] ?? {};

        meta.forEach((item) => {
            const { audience, count: unreadMsgCount, latestMessage, tags } = item;
            const { unread: unreadTaggedMsgCount } = ((tags && Object.values(tags)[0]) || {}) as { unread?: number };
            state.chatReadStatus[channelName][audience] = state.chatReadStatus[channelName][audience] ?? {};
            state.chatReadStatus[channelName][audience].isTagged = unreadTaggedMsgCount > 0;

            if (latestMessage.createdBy === payload.galaxyId) {
                state.chatReadStatus[channelName][audience].isRead = true;
            } else {
                state.chatReadStatus[channelName][audience].isRead = unreadMsgCount === 0;
            }
        });
    });
};

export const selectChat = (state: RootState) => state.chat;

export default chatSlice.reducer;

const {
    updateChatReadStatus,
    selectChatroom,
    addFlightChannels,
    moveChannelToFlightChannelsTop,
    removeEmptyChat,
    setNewlyAddedFlight,
    setLoading,
    setEventListToChatUfi,
    updateAllChannelLastUpdatedTime,
} = chatSlice.actions;
export {
    updateChatReadStatus,
    selectChatroom,
    addFlightChannels,
    moveChannelToFlightChannelsTop,
    removeEmptyChat,
    setNewlyAddedFlight,
    setLoading,
    setEventListToChatUfi,
    updateAllChannelLastUpdatedTime,
    chatMessageAdapter,
};
